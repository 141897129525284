import React from "react";

import { useNavigation } from "./NavigationProvider";

interface RedirectProps {
  to: string;
  hardNav?: boolean; // used if need to hard navigate (e.g to external url, or ssr/nextjs page)
}

/**
 * react-router v5 doesn't work well with React 18. This is a workaround which uses our built-in navigation hooks.
 * But also works with hookrouter, which is neat.
 * @param props RedirectProps
 * @returns null
 */
export const Redirect = (props: RedirectProps) => {
  const navigate = useNavigation();

  React.useEffect(() => {
    navigate(props.to, {
      replace: true,
      hardNav: props.hardNav,
    });
  }, [navigate, props.to, props.hardNav]);
  return null;
};
