import { UsersRow } from "@outschool/db-queries";
import { normalizeString } from "@outschool/text";
import * as Time from "@outschool/time";

export const DEFAULT_PARENT_NAME = "An Outschool parent";

export default class User {
  private isBrowser: boolean;
  constructor(isBrowser?: boolean) {
    this.isBrowser = isBrowser || typeof window !== "undefined";
  }

  locationTimeZone(user: UsersRow) {
    return user?.location?.timeZone;
  }

  browserTimeZone(user: UsersRow) {
    return this.isBrowser
      ? Time.guessBrowserTimeZone()
      : user?.details?.browserTimeZone;
  }

  timeZone(user: UsersRow, defaultTimeZone = Time.OUTSCHOOL_TIMEZONE) {
    const locationZone = this.locationTimeZone(user);
    if (Time.zoneIsValid(locationZone)) {
      return locationZone;
    }
    const browserZone = this.browserTimeZone(user);
    if (Time.zoneIsValid(browserZone)) {
      return browserZone;
    }
    return defaultTimeZone;
  }

  isDeleted(user: { deleted_at?: Date | null }) {
    return user && !!user.deleted_at;
  }

  isAdmin(user: Pick<UsersRow, "is_admin">): boolean {
    return user.is_admin ?? false;
  }

  firstAndLastName(user: { name: string | null; deleted_at?: Date | null }) {
    if (this.isDeleted(user) || !user.name) {
      return [];
    }
    let tokens = user.name.split(" ");
    let endsWithDot = /\.$/.test(tokens[0]);
    if (tokens.length <= 2 && !endsWithDot) {
      return [tokens[0], tokens[1]];
    }
    return [
      `${tokens[0]} ${tokens[1]}`,
      tokens.length > 2 ? tokens.slice(2).join(" ") : undefined,
    ];
  }

  firstName(user: { name: string | null; deleted_at?: Date | null }) {
    if (user && user.name && user.name === DEFAULT_PARENT_NAME) {
      return user.name;
    }
    const names = this.firstAndLastName(user);
    return names && names[0];
  }

  standardNormalize(user: Partial<UsersRow>) {
    return (
      user && {
        ...user,
        email: user.email ? normalizeString(user.email) : user.email,
      }
    );
  }

  shortenedLeaderName(leaderName?: string | null) {
    if (!leaderName) {
      return "";
    }
    if (leaderName.length <= 30) {
      return leaderName;
    }
    return leaderName.slice(0, 30).concat("...");
  }

  holdMessagesFrom(user: {
    details: { holdMessages?: any };
    suspended_at: Date | null;
  }) {
    return (
      (user && user.details && user.details.holdMessages) ||
      this.isSuspended(user)
    );
  }

  isSuspended(user: { suspended_at: Date | null }) {
    return Boolean(user && user.suspended_at);
  }
}
