import { CookieKeys } from "@outschool/data-schemas";
import {
  fetchWebsiteExpAssignment,
  EVENT_SOURCE,
  HOLDOUT,
} from "@outschool/experiments-shared";
import { useLocalStorageState } from "@outschool/local-storage";
import { Component } from "@outschool/ownership-areas";
import { useTokenContext } from "@outschool/ui-auth";
import { useIsBot } from "@outschool/ui-utils";
import Cookies from "js-cookie";
import isEqual from "lodash/isEqual";
import React, { PropsWithChildren } from "react";
import { v4 } from "uuid";

export const ExperimentsServiceContext = React.createContext<{
  isProduction: boolean;
  isExperimentOverridesEnabled?: boolean;
  eventSource: EVENT_SOURCE;
  loggedInUserExperimentUid: string | undefined;
  loggedOutUserExperimentUid: string | undefined;
  experimentAssignments: Record<string, string>;
  experimentOverrides: Record<string, string>;
  setExperimentOverrides(overrides: Record<string, string>): void;
  shouldTrigger: ({
    experimentName,
    variantAssigned,
    additionalProperties,
  }: {
    experimentName: string;
    variantAssigned: string;
    additionalProperties?: Record<any, any> | undefined;
  }) => boolean;
}>({
  isProduction: false,
  eventSource: EVENT_SOURCE.BROWSER,
  isExperimentOverridesEnabled: false,
  loggedInUserExperimentUid: undefined,
  loggedOutUserExperimentUid: undefined,
  experimentAssignments: {},
  experimentOverrides: {},
  setExperimentOverrides: () => null,
  shouldTrigger: () => true,
});

export function useServerExperimentContext() {
  const context = React.useContext(ExperimentsServiceContext);

  if (context === undefined) {
    throw new Error(
      "useServerExperimentContext called outside of a ExperimentsServiceProvider"
    );
  }

  return context;
}

function getLoggedOutUserExpUid({
  osEnv,
  loggedOutUserExperimentUid,
}: {
  osEnv: string;
  loggedOutUserExperimentUid?: string;
}): string | undefined {
  // server-side
  if (typeof window === "undefined") {
    // ssr-cloudflare-worker has created a loggedOutUserExperimentUid
    if (!!loggedOutUserExperimentUid) {
      Cookies.set(
        CookieKeys.LoggedOutUserExperimentUid,
        loggedOutUserExperimentUid,
        {
          expires: 365, // days
          path: "/",
          sameSite: "lax",
          secure: !(osEnv === "test" || osEnv === "development"),
        }
      );
      return loggedOutUserExperimentUid;
    } else {
      return undefined;
    }
  }

  let uid = Cookies.get(CookieKeys.LoggedOutUserExperimentUid);

  if (!!uid) {
    return uid;
  }

  uid = v4();

  Cookies.set(CookieKeys.LoggedOutUserExperimentUid, uid, {
    expires: 365, // days
    path: "/",
    sameSite: "lax",
    secure: !(osEnv === "test" || osEnv === "development"),
  });

  return uid;
}

async function fetchExperimentAssignments({
  loggedInUserExpUid,
  loggedOutUserExpUid,
  experimentsServiceUrl,
  isBotRequest,
  isTest,
  timeoutMs,
}: {
  loggedInUserExpUid?: string;
  loggedOutUserExpUid?: string;
  experimentsServiceUrl: string;
  isBotRequest: boolean;
  isTest: boolean;
  timeoutMs?: number;
}) {
  if (
    !isBotRequest &&
    !isTest &&
    (!!loggedOutUserExpUid || !!loggedInUserExpUid)
  ) {
    try {
      const assignmentResponse = await fetchWebsiteExpAssignment({
        fetchFn: fetch,
        experimentsServiceUrl,
        loggedInUserExpUid,
        loggedOutUserExpUid,
        timeoutMs,
      });
      return assignmentResponse;
    } catch (err) {
      OsPlatform.captureError(err, {
        component: Component.ExperimentationFramework,
      });
      return {};
    }
  }
  return {};
}

type TriggerValue = {
  variantAssigned: string;
  additionalProperties?: Record<any, any>;
};

export const ExperimentsServiceProvider: React.FC<
  PropsWithChildren<{
    initialExperimentAssignments: Record<string, string>;
    experimentsServiceUrl: string;
    isTest: boolean;
    isProduction: boolean;
    osEnv: string;
    isExperimentOverridesEnabled?: boolean;
    timeoutMs?: number;
    eventSource: EVENT_SOURCE;
    loggedOutUserExperimentUid?: string;
  }>
> = ({
  children,
  initialExperimentAssignments,
  experimentsServiceUrl,
  isTest,
  isProduction,
  osEnv,
  isExperimentOverridesEnabled,
  timeoutMs,
  eventSource,
  loggedOutUserExperimentUid,
}) => {
  const { getTokens } = useTokenContext();
  const loggedInUserExpUid = getTokens()?.decodedToken?.uid;

  const loggedOutUserExpUid = React.useRef(
    getLoggedOutUserExpUid({ osEnv, loggedOutUserExperimentUid })
  );
  const [experimentOverrides, setExperimentOverrides] = useLocalStorageState(
    "SERVER_SIDE_EXPERIMENT_ASSIGNMENT_OVERRIDES",
    {}
  );
  const [experimentAssignments, setExperimentAssignments] = React.useState(
    initialExperimentAssignments
  );

  const isBotRequest = useIsBot();

  const experimentTriggers = React.useRef<Record<string, TriggerValue>>({});
  const shouldTrigger = React.useCallback(
    ({
      experimentName,
      variantAssigned,
      additionalProperties,
    }: {
      experimentName: string;
      variantAssigned: string;
      additionalProperties?: Record<any, any>;
    }) => {
      if (variantAssigned === HOLDOUT) {
        return false;
      }
      const triggerValue = {
        variantAssigned,
        additionalProperties,
      };
      if (isEqual(experimentTriggers.current[experimentName], triggerValue)) {
        return false;
      }
      experimentTriggers.current[experimentName] = triggerValue;
      return true;
    },
    []
  );

  // This will allow us to pass experiment overrides to the server side.
  React.useEffect(() => {
    Cookies.set("experimentOverrides", JSON.stringify(experimentOverrides));
  }, [experimentOverrides]);

  React.useEffect(() => {
    async function fetch() {
      const assignments = await fetchExperimentAssignments({
        loggedInUserExpUid,
        loggedOutUserExpUid: loggedOutUserExpUid.current,
        experimentsServiceUrl,
        isBotRequest,
        isTest,
        timeoutMs,
      });
      setExperimentAssignments(assignments);
    }
    fetch();
  }, [
    loggedInUserExpUid,
    experimentsServiceUrl,
    isBotRequest,
    isTest,
    timeoutMs,
  ]);

  return (
    <ExperimentsServiceContext.Provider
      value={{
        isProduction,
        eventSource,
        isExperimentOverridesEnabled,
        loggedInUserExperimentUid: loggedInUserExpUid,
        loggedOutUserExperimentUid: loggedOutUserExpUid.current,
        experimentAssignments,
        experimentOverrides,
        setExperimentOverrides,
        shouldTrigger,
      }}
    >
      {children}
    </ExperimentsServiceContext.Provider>
  );
};
